import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {


 
  const Logout = () => {
    localStorage.clear()

  }
  return (
    <div className="sidebar">
      <div className="brand-logo">
        <Link className="full-logo" to="/">
          <img src="/images/logoi.png" alt="" width={30} />
        </Link>
      </div>
      <div className="menu">
        <ul>
          <li>
            <Link to="/">
              <span>
                <i className="fi fi-rr-dashboard" />
              </span>
              <span className="nav-text">Home</span>
            </Link>
          </li>
          <li>
            <Link to="/stripe">
              <span>
              <span>
                <i class="fi fi-brands-stripe"></i>{" "}
              </span>
              </span>
              <span className="nav-text">Create Account</span>
            </Link>
          </li>
     
            <li>
              <Link to="/transaction">
                <span>
                  <i class="fi fi-rr-bank"></i>{" "}
                </span>
                <span className="nav-text">Send Money</span>
              </Link>
            </li> 

          <li>
            <Link
              to={"/login"}
              onClick={Logout}
            >
              <span>
                <i className="fi fi-bs-sign-out-alt" />
              </span>{" "}
              <span className="nav-text">Logout</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/wallets">
              <span>
                <i className="fi fi-rr-wallet" />
              </span>
              <span className="nav-text">Wallets</span>
            </Link>
          </li> */}

          {/* <li>
            <Link to="/bank">
              <span>
                <i class="fi fi-rr-bank"></i>{" "}
              </span>
              <span className="nav-text">Bank</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/security">
              <span>
                <i class="fi fi-rr-shield-check"></i>{" "}
              </span>
              <span className="nav-text">Security</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="budgets.html">
              <span>
                <i className="fi fi-rr-donate" />
              </span>
              <span className="nav-text">Budgets</span>
            </Link>
          </li>
          <li>
            <Link to="goals.html">
              <span>
                <i className="fi fi-sr-bullseye-arrow" />
              </span>
              <span className="nav-text">Goals</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/profile">
              <span>
                <i className="fi fi-rr-user" />
              </span>
              <span className="nav-text">Profile</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <span>
                <i class="fi fi-rr-settings"></i>{" "}
              </span>
              <span className="nav-text">Account</span>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/Addnewaccount">
              <span>
                <i class="fi fi-brands-stripe"></i>{" "}
              </span>
              <span className="nav-text">Connet Stripe</span>
            </Link>
          </li> */}

          {/* <li>
            <Link to="analytics.html">
              <span>
                <i className="fi fi-rr-chart-histogram" />
              </span>
              <span className="nav-text">Analytics</span>
            </Link>
          </li>
          <li>
            <Link to="support.html">
              <span>
                <i className="fi fi-rr-user-headset" />
              </span>
              <span className="nav-text">Support</span>
            </Link>
          </li>
          <li>
            <Link to="affiliates.html">
              <span>
                <i className="fi fi-rs-link-alt" />
              </span>
              <span className="nav-text">Affiliates</span>
            </Link>
          </li>
          <li>
            <Link to="settings.html">
              <span>
                <i className="fi fi-rs-settings" />
              </span>
              <span className="nav-text">Settings</span>
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
