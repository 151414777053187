import { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { axiosInstance } from "../../../config/AxiosInstance";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";

export default function Transections() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [email, setEmail] = useState("")
    const [desid, setDesid] = useState("")

    const [amount, setAmount] = useState("")


    const StripeId = localStorage.getItem("StripeId")
    const Email = localStorage.getItem("email")



    useEffect(() => {
        GetStripeacc()

    }, [])

    // GET TRIPE ACCT 
    const GetStripeacc = () => {
        setLoader(true)

        axiosInstance
            .get('get_stripe_account')
            .then((response) => {
                setLoader(false)
                const data = response.data;
                console.log("get_stripe_account", data)
                if (data) {
                    setData(data)
                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const Error = err?.response?.data?.detail
                setLoader(false)
                toast.error(Error);
            });

    }

    // PAYMENT 

    const Payment = () => {
        if(StripeId === ""){
            toast.error("Create your stripe account first")
            navigate("/stripe")
        }
        else{

     
            setLoader(true);

        // Assuming you have four fields: field1, field2, field3, field4
        const requestData = {
            amount: amount,
            destination: desid,
            to_email: email,
            from_email: Email,
            from_stripe_id: StripeId

        };

        axiosInstance
            .post('transfer_payment', requestData)
            .then((response) => {
                setLoader(false);
                const data = response.data;
                console.log("data transfer",data)

                if (data) {
                    CloseConfModal()
                    toast.success(data.detail);
                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const errorDetail = err?.response?.data?.detail;
                setLoader(false);
                toast.error(errorDetail);
            });
        }
    };

    const CloseAddmodal = () => {

        const deletemodal = document.getElementById("exampleModal");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


    const Remove = () => {
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
            modalBackdrop.remove();
        }
    }

    const GetEmail = (data) => {
        setEmail(data.email)
        setDesid(data.stripe_id)

    }

    const CloseFirstmodal = () => {
        CloseAddmodal()
        const payButton = document.querySelector('[data-bs-target="#Confirmation"]');

        console.log("payButton", payButton)


    }


    const CloseConfModal = () => {

        const Confirm = document.getElementById("Confirmation");
        console.log("Confirm", Confirm)

        // Simulate a click on the close button to close the modal
        const closeBtn = Confirm.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="content-body">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-xl-4">
                                        <div className="page-title-content">
                                            <h3>Send Money</h3>
                                            <p className="mb-2">Welcome P2P Finance Management</p>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="breadcrumbs">
                                            <Link to="#">Home </Link>
                                            <span>
                                                <i className="fi fi-rr-angle-small-right" />
                                            </span>
                                            <Link to="#">Send Money</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12">

                            <div className="row">

                                <div className="col-xxl-12 col-xl-12 col-lg-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Stripe Accounts</h4>


                                        </div>
                                        <div className="card-body">
                                            <div className="category-type">
                                                {data.map((v, i) => (

                                                    <ul>
                                                        <li>
                                                            <div className="left-category">
                                                                
                                                                <span className="category-icon">
                                                                   
                                                                    {v.email}
                                                                </span>
                                                            </div>
                                                            <div className="right-category">
                                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { Remove(); GetEmail(v) }}>
                                                                    Pay
                                                                </button>


                                                            </div>
                                                        </li>

                                                    </ul>

                                                ))}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ADD  */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Send Money
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label">From Account</label>
                                    <input value={Email} type="text" className="form-control"  disabled />
                                </div>
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label">To Account</label>
                                    <input value={email} type="text" className="form-control"  disabled />
                                </div>
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label"> Amount</label>
                                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control" placeholder="Amount" />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Confirmation" onClick={() => { CloseFirstmodal(); Remove() }}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation  */}
            <div
                className="modal fade"
                id="Confirmation"
                tabIndex={-1}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Confirmation
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label">From Account</label>
                                    <input value={Email} type="text" className="form-control"  disabled />
                                </div>
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label">To Account</label>
                                    <input value={email} type="text" className="form-control" disabled />
                                </div>
                                <div className="col-xxl-12 col-12 mb-3">
                                    <label className="form-label">Total Amount</label>
                                    <input type="number" value={amount} className="form-control" placeholder="Amount" disabled />
                                </div>


                            </div>


                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>

                           

                            <button className="btn btn-primary" type="button" onClick={() => Payment()} disabled={loader}>
                                {loader ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </>
                                ) : (
                                    'Pay Now'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>





    )
}
