import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <div id="main-wrapper">
      <Header />
      <Sidebar />
      <div className="content-body">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
