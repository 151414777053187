import React from "react";
import { Link } from "react-router-dom";

const Homesection = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-4">
              <div className="page-title-content">
                <h3>Dashboard</h3>
                <p className="mb-2">Welcome P2P Finance Management</p>
              </div>
            </div>
            <div className="col-auto">
              <div className="breadcrumbs">
                <Link to="#">Home </Link>
                <span>
                  <i className="fi fi-rr-angle-small-right" />
                </span>
                <Link to="#">Dashboard</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homesection;
