import React from 'react'
import Layout from "../components/layout/layout";
import Stripe from '../components/pages/createstripe/stripe';
export default function CreateStripe() {
  return (
    <Layout>
        <Stripe/>
    </ Layout>
  )
}
