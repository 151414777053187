import {useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../Input";
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../config/AxiosInstance";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";

const ChangePassword = () => {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const location = useLocation()

  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const onSubmit = (data) => {
    setLoader(true)

    if (data.password !== data.newpassword) {
      setLoader(false);
      toast.error("Password and New Password do not match");
      return;
    }
    
    const combinedData = {
        // Form data
      id: location.state.id,  // Adding id from location.state
      newpassword: data.newpassword
    };
    axiosInstance
      .post("reset_password", combinedData)
      .then((response) => {
    setLoader(false)

        const data = response.data;
        if (data.status) {
          navigate('/login');
          toast.success(data.detail);
        } else {
          toast.error(data.detail);
        }
      })
      .catch((err) => {
        const Error = err?.response?.data?.detail
        setLoader(false)
        toast.error(Error);
      });
  };

  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
  
    <div className="authincation">
      <div className="container">
        <div className="row justify-content-center align-items-center g-0">
          <div className="col-xl-8">
            <div className="row g-0">
              <div className="col-lg-6">
                <div className="welcome-content">
                  <div className="welcome-title">
                    <div className="mini-logo">
                      <Link to="/">
                        <img src="images/logo-white.png" alt="" width={30} />
                      </Link>
                    </div>
                    <h3>Welcome to P2P</h3>
                  </div>
                  {/* <div className="privacy-social">
                    <div className="privacy-link">
                      <Link to={"#"}>
                        Have an issue with 2-factor authentication?
                      </Link>
                      <br />
                      <Link to={"#"}>Privacy Policy</Link>
                    </div>
                    <div className="intro-social">
                      <ul>
                        <li>
                          <Link to={"#"}>
                            <i className="fi fi-brands-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            <i className="fi fi-brands-twitter-alt" />
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            <i className="fi fi-brands-linkedin" />
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            <i className="fi fi-brands-pinterest" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="auth-form">
                  <h4>Change Password</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Input
                          name="password"
                          type="password"
                          label={"Password"}
                          control={control}
                          className={"form-label"}
                          rules={{
                            required: "Password is Required",
                            
                          }}
                          errors={errors}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <Input
                          name="newpassword"
                          type="password"
                          label={"New Password"}
                          control={control}
                          className={"form-label"}
                          rules={{
                            required: "Password is Required",
                          }}
                          errors={errors}
                        />
                      </div>
                      
                     
                    </div>
                    <div className="mt-3 d-grid gap-2">
                      <button
                        type="submit"
                        className="btn btn-primary me-8 text-white"
                      >
                       Submit
                      </button>
                    </div>
                  </form>

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ChangePassword;
