import React from "react";
import Layout from "../components/layout/layout";
// import Walletsection1 from "../components/pages/wallet/walletsection1";
// import Walletsection2 from "../components/pages/wallet/walletsection2";

const Wallet = () => {
  return (
    <Layout>
      <div className="container">
        {/* <Walletsection1 /> */}
        {/* <Walletsection2 /> */}
      </div>
    </Layout>
  );
};

export default Wallet;
