import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="copyright">
              <p>
                © Copyright
                <Link to="#">P2P</Link> I All Rights Reserved
              </p>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="footer-social">
              <ul>
                <li>
                  <Link to="#">
                    <i className="fi fi-brands-facebook" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fi fi-brands-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fi fi-brands-linkedin" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fi fi-brands-youtube" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
