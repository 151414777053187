import { useState, useEffect } from "react";
import { axiosInstance } from "../../../config/AxiosInstance";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";



const Homesection3 = () => {
  const [tpayment, setTpayment] = useState([])
  const [rpayment, setRpayment] = useState([])
  const [loader, setLoader] = useState(false)
  const Email = localStorage.getItem("email")

  useEffect(() => {

    GetPaymentHis(Email)

  }, [Email])


  const GetPaymentHis = (Email) => {
    setLoader(true)

    axiosInstance
      .get(`get_payment_history/${Email}`)
      .then((response) => {
        setLoader(false)
        const data = response.data;
        console.log("paym", data)
        if (data) {
          setTpayment(data.transfer_payments)
          setRpayment(data.received_payments)
        } else {
          toast.error(data.detail);
        }
      })
      .catch((err) => {
        const Error = err?.response?.data?.detail
        setLoader(false)
        toast.error(Error);
      });

  }
  return (

    <>
      {loader === true ? <Loader fullPage loading /> : null}


      <div className="row">

        {/* Transfer  */}

        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Transfer Payment</h4>
            </div>
            <div className="card-body">
              <div className="transaction-table">
                <div className="table-responsive">

                  <table className="table mb-0 table-responsive-sm">
                    <thead>
                      <tr>
                        <th>To Email</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>

                      </tr>
                    </thead>
                    {tpayment.map((v, i) => (
                      <tbody>
                        <tr>
                          <td>
                            <span className="table-category-icon">
                              {/* <i className="bg-emerald-500 fi fi-rr-barber-shop" /> */}
                              {v.to_email}
                            </span>
                          </td>
                          <td>{new Date(v.created_at).toLocaleDateString('en-US')}{' '} </td>

                          <td> {new Date(v.created_at).toLocaleTimeString('en-US')}</td>

                          <td><strong>$</strong> {v.amount}</td>

                        </tr>

                      </tbody>

                    ))}

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Recevied  */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Recevied Payment</h4>
            </div>
            <div className="card-body">
              <div className="transaction-table">
                <div className="table-responsive">

                  <table className="table mb-0 table-responsive-sm">
                    <thead>
                      <tr>
                        <th>From Email</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>

                      </tr>
                    </thead>
                    {rpayment.map((v, i) => (
                      <tbody>
                        <tr>
                          <td>
                            <span className="table-category-icon">
                              {/* <i className="bg-emerald-500 fi fi-rr-barber-shop" /> */}
                              {v.from_email}
                            </span>
                          </td>
                          <td>{new Date(v.created_at).toLocaleDateString('en-US')}{' '} </td>

                          <td> {new Date(v.created_at).toLocaleTimeString('en-US')}</td>

                          <td><strong>$</strong> {v.amount}</td>

                        </tr>

                      </tbody>

                    ))}

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Homesection3;
