import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { axiosInstance } from "../../../config/AxiosInstance";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";


export default function Stripe() {

    const [loader, setLoader] = useState(false)
    const [stripeid, setStripeid] = useState('')
    const [stripedata, setStripedata] = useState([])
    console.log("stripeid", stripeid)
    console.log("stripedata", stripedata)


    // const [btndisable, setBtndisable] = useState(false)



    const [email, setEmail] = useState('');
    console.log(email)
    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []); // This useEffect runs only once when the component mounts

    const { handleSubmit } = useForm();

    // generate stripe account 
    const onSubmit = () => {
        const data = {
            email: email
        }
        setLoader(true)

        axiosInstance
            .post("create_stripe_account/", data)
            .then((response) => {
                setLoader(false)
                const data = response.data;
                setStripeid(data.stripe_account)
                console.log("data", data)
                localStorage.setItem("StripeId", data?.stripe_account)

                if (data.status) {
                    // setBtndisable(true)
                    toast.success(data.detail);
                    ConnectoLink(data?.stripe_account)

                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const Error = err?.response?.data?.detail
                setLoader(false)
                toast.error(Error);
            });
    };

    // conect to connect acc 
    const ConnectoLink = (stripeid) => {
        setLoader(true)
        const dataWithStripeId = {
            "account": stripeid
        };
        axiosInstance
            .post("connect_to_connect_account/", dataWithStripeId)
            .then((response) => {
                setLoader(false)
                const data = response.data;
                if (data.status) {
                    console.log("connect", data)
                    toast.success(data.detail);
                    setStripedata(data)
                    // setBtndisable(false)

                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const Error = err?.response?.data?.detail
                setLoader(false)
                toast.error(Error);
            });
    }

    const openLinkInNewTab = (link) => {
        window.open(link, '_blank');
    }

    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}

            <div id="main-wrapper">
                <div className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4">
                                            <div className="page-title-content">
                                                <h3>Create Stripe</h3>
                                                <p className="mb-2">Welcome P2P Finance Management</p>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="breadcrumbs">
                                                <Link to="#">Home </Link>
                                                <span>
                                                    <i className="fi fi-rr-angle-small-right" />
                                                </span>
                                                <Link to="#">Create Stripe</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">


                            <div className="col-xl-9 col-lg-9">
                                <div className="card">

                                    <div className="card-body">

                                        <div className="referral-form">
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-row align-items-center">

                                                    <div className="col-12 mb-3">
                                                        <div className="col-xxl-12 col-12 mb-3">
                                                            <label className="form-label">Email</label>
                                                            <input type="email" value={email} className="form-control" onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 d-flex justify-content-between'>
                                                            <button disabled={stripeid !== ""} type='submit' class="btn btn-primary">Generate Stripe Account</button>

                                                            {/* <button type='button' disabled={btndisable !== true} class="btn btn-primary" onClick={() => ConnectoLink()}>Connect to Link</button> */}
                                                            {/* <button type='button' class="btn btn-primary" onClick={() => ConnectoLink()}>Connect to Link</button> */}


                                                        </div>
                                                    </div>



                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {stripedata?.status === true  ?

                                <div className="col-xl-9 col-lg-9">
                                    <div className="card">

                                        <div className="card-body">

                                            <div className="referral-form">
                                                <form >
                                                    <div className="form-row align-items-center">

                                                        <div className="col-12 mb-3">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h4 className="card-title">Stripe Account Details</h4>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="transaction-table">
                                                                        <div className="table-responsive">

                                                                            <table className="table mb-0 table-responsive-sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Email</th>
                                                                                        <th>Date</th>
                                                                                        <th>Link</th>

                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span className="table-category-icon">
                                                                                                {/* <i className="bg-emerald-500 fi fi-rr-barber-shop" /> */}
                                                                                                {stripedata.email}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>{new Date(stripedata.created_at).toLocaleDateString('en-US')}</td>

                                                                                        <td>  <button type='button' class="btn btn-primary" onClick={() => openLinkInNewTab(stripedata.link)}>Verify Link</button></td>



                                                                                    </tr>

                                                                                </tbody>

                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                        <div className='col-md-12 d-flex justify-content-between'>
     

                                                            {/* <button type='button' disabled={btndisable !== true} class="btn btn-primary" onClick={() => ConnectoLink()}>Connect to Link</button> */}
                                                            <button type='button' class="btn btn-primary" onClick={() => ConnectoLink(stripeid)}>Re-Generate Verification Link</button>


                                                        </div>
                                                    </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div> : ""}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
