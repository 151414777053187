import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/AxiosInstance";
import { useForm } from "react-hook-form";
import Input from "../Input";
import { Loader } from "react-overlay-loader";

const Signup = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoader(true)
    axiosInstance
      .post("signup", data)
      .then((response) => {
        setLoader(false)
        const resData = response.data;

        if (resData.status) {
          const sanitizedData = { ...resData.data };
          delete sanitizedData.otp;
          navigate('/verification', { state: { data: sanitizedData } });

          toast.success(resData.detail);
        } else {
          setLoader(false)
          toast.error(resData.detail);
        }
      })
      .catch((err) => {
        const Error = err?.response?.data?.detail
        setLoader(false)
        toast.error(Error);

      });
  };
  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}


      <div className="authincation">
        <div className="container">
          <div className="row justify-content-center align-items-center g-0">
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="welcome-content">
                    <div className="welcome-title">
                      <div className="mini-logo">
                        <Link to="/">
                          <img src="images/logo-white.png" alt="" width={30} />
                        </Link>
                      </div>
                      <h3>Welcome to P2P</h3>
                    </div>
                    {/* <div className="privacy-social">
                      <div className="privacy-link">
                        <Link to="#">
                          Have an issue with 2-factor authentication?
                        </Link>
                        <br />
                        <Link to="#">Privacy Policy</Link>
                      </div>
                      <div className="intro-social">
                        <ul>
                          <li>
                            <Link to="#">
                              <i className="fi fi-brands-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fi fi-brands-twitter-alt" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fi fi-brands-linkedin" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fi fi-brands-pinterest" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="auth-form">
                    <h4>Sign Up</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12 ">
                          <Input
                            name="fname"
                            type="text"
                            label={"First Name"}
                            control={control}
                            className={"form-label"}
                            rules={{
                              required: "First Name is Required",
                            }}
                            errors={errors}
                          />
                        </div>
                        <div className="col-12 ">
                          <Input
                            name="lname"
                            type="text"
                            label={"Last Name"}
                            control={control}
                            className={"form-label"}
                            rules={{
                              required: "Last Name is Required",
                            }}
                            errors={errors}
                          />
                        </div>
                        <div className="col-12 ">
                          <Input
                            name="username"
                            type="text"
                            label={"User Name"}
                            control={control}
                            className={"form-label"}
                            rules={{
                              required: "User Name is Required",
                            }}
                            errors={errors}
                          />
                        </div>
                        <div className="col-12 ">
                          <Input
                            name="email"
                            type="email"
                            label={"Email"}
                            control={control}
                            className={"form-label"}
                            rules={{
                              required: "Email is Required",
                              pattern: /^\S+@\S+$/i,
                            }}
                            errors={errors}
                          />
                        </div>
                        <div className="col-12 ">
                          <Input
                            name="password"
                            type="password"
                            label={"Password"}
                            control={control}
                            className={"form-label"}
                            rules={{
                              required: "Password is Required",
                            }}
                            errors={errors}
                          />
                        </div>

                      </div>
                      <div className="mt-3 d-grid gap-2">
                        <button
                          type="submit"
                          className="btn btn-primary me-8 text-white"
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                    <p className="mt-3 mb-0 undefined">
                      Already have an account?
                      <Link className="text-primary" to="/login">
                        {" "}
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
