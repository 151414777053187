import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, } from "react-router-dom";
import { selectUser } from "../../config/slice/userSlice";

const Header = () => {
  const [darkMode, setDarkMode] = useState(false);
  const { userData } = useSelector(selectUser);

  useEffect(() => {
    document.body.classList.toggle("dark-theme", darkMode);
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };




  const Logout = () => {
    localStorage.clear()

  }
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="header-content">
              <div className="header-left">
                <div className="brand-logo">
                  <Link className="mini-logo" to="/">
                    <img src="/images/logoi.png" alt="" width={40} />
                  </Link>
                </div>
                {/* <div className="search">
                  <form action="#">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Here"
                      />
                      <span className="input-group-text">
                        <i className="fi fi-br-search" />
                      </span>
                    </div>
                  </form>
                </div> */}
              </div>
              <div className="header-right">
                <div className="dark-light-toggle" onClick={toggleTheme}>
                  <span className="dark">
                    <i className="fi fi-rr-eclipse-alt" />
                  </span>
                  <span className="light">
                    <i className="fi fi-rr-eclipse-alt" />
                  </span>
                </div>
                <div className="nav-item dropdown notification">
                  <div data-bs-toggle="dropdown">
                    <div className="notify-bell icon-menu">
                      <span>
                        <i className="fi fi-rs-bells" />
                      </span>
                    </div>
                  </div>
                  <div
                    tabIndex={-1}
                    role="menu"
                    aria-hidden="true"
                    className="dropdown-menu dropdown-menu-end"
                  >
                    <h4>Recent Notification</h4>
                    <div className="lists">
                      <Link className="" to="#">
                        <div className="d-flex align-items-center">
                          <span className="me-3 icon success">
                            <i className="fi fi-bs-check" />
                          </span>
                          <div>
                            <p>Account created successfully</p>
                            <span>2024-11-04 12:00:23</span>
                          </div>
                        </div>
                      </Link>
                      <Link className="" to="#">
                        <div className="d-flex align-items-center">
                          <span className="me-3 icon fail">
                            <i className="fi fi-sr-cross-small" />
                          </span>
                          <div>
                            <p>2FA verification failed</p>
                            <span>2024-11-04 12:00:23</span>
                          </div>
                        </div>
                      </Link>
                      <Link className="" to="#">
                        <div className="d-flex align-items-center">
                          <span className="me-3 icon success">
                            <i className="fi fi-bs-check" />
                          </span>
                          <div>
                            <p>Device confirmation completed</p>
                            <span>2024-11-04 12:00:23</span>
                          </div>
                        </div>
                      </Link>
                      <Link className="" to="#">
                        <div className="d-flex align-items-center">
                          <span className="me-3 icon pending">
                            <i className="fi fi-rr-triangle-warning" />
                          </span>
                          <div>
                            <p>Phone verification pending</p>
                            <span>2024-11-04 12:00:23</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="more">
                      <Link to="notifications.html">
                        More
                        <i className="fi fi-bs-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="dropdown profile_log dropdown">
                  <div data-bs-toggle="dropdown">
                    <div className="user icon-menu active">
                      <span>
                        <i className="fi fi-rr-user" />
                      </span>
                    </div>
                  </div>
                  <div
                    tabIndex={-1}
                    role="menu"
                    aria-hidden="true"
                    className="dropdown-menu dropdown-menu dropdown-menu-end"
                  >
                    <div className="user-email">
                      <div className="user">
                        <span className="thumb">
                          <img
                            className="rounded-full"
                            src="/images/avatar/3.jpg"
                            alt=""
                          />
                        </span>
                        <div className="user-info">
                          <h5>{userData?.full_name}</h5>
                          <span>{userData?.email}</span>
                        </div>
                      </div>
                    </div>
                    {/* <Link className="dropdown-item" to="/profile">
                      <span>
                        <i className="fi fi-rr-user" />
                      </span>
                      Profile
                    </Link> */}
                    {/* <Link className="dropdown-item" to="/wallets">
                      <span>
                        <i className="fi fi-rr-wallet" />
                      </span>
                      Wallets
                    </Link>
                    <Link className="dropdown-item" to="/account">
                      <span>
                        <i className="fi fi-rr-settings" />
                      </span>
                      Settings
                    </Link> */}
                    <Link
                      className="dropdown-item logout"
                      to={"/login"}
                      onClick={Logout}
   
                    >
                      <span>
                        <i className="fi fi-bs-sign-out-alt" />
                      </span>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
