import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  // baseURL: "https://p2papi.dev-mm.xyz/api/user/",
  baseURL: "http://167.114.96.66:2001/",

});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const Data = error?.response?.data;
    toast.error(Data.message);
    return Promise.reject(error);
  }
);
