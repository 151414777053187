import React from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import {useState} from "react";
import Input from "../Input";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/AxiosInstance";
import { useForm } from "react-hook-form";
import { Loader } from "react-overlay-loader";



const Verifications = () => {

  const location = useLocation()
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  console.log("otp",location.state)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoader(true)
    const combinedData = {
      ...data,   // Form data
      id: location.state.id,  // Adding id from location.state
    };
    axiosInstance
      .post("check_otp", combinedData)
      .then((response) => {
        setLoader(false)
        const data = response.data;

        if (data.status) {
                   navigate('/changepassword', { state: { id: data.id } });

          toast.success(data.detail);
        } else {
          setLoader(false)
          toast.error(data.detail);
        }
      })
      .catch((err) => {
    
        const Error = err?.response?.data?.detail
        setLoader(false)
        toast.error(Error);
      });
  };

  return (
    <>
         {loader === true ? <Loader fullPage loading /> : null}
  
    <div className="authincation">
    <div className="container h-100">
      <div className="row justify-content-center h-100 align-items-center">
        <div className="col-xl-5 col-md-6">
          <div className="card">
            <div className="card-body">
              {/* <Link className="page-back text-muted" to="/otp-verifications">
              <span>
                <i className="fi fi-ss-angle-small-left" />
              </span>{" "}
              Back
            </Link> */}
              <h3 className="text-center">OTP Verification</h3>
              <p className="text-center mb-5">
                We will send one time 6 digit code on your email{" "}
                <b>
                  {/* <i>{email}.</i> */}
                </b>
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3  mb-3">
                  <label className="mb-3">Your OTP Code</label>
                  <div className="input-group">
                    {/* <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fi fi-sr-phone-call" />
                      </span>
                    </div> */}

                    <Input
                      name="otp"
                      type="number"
                      label={""}
                      control={control}
                      className={"form-label"}
                      rules={{
                        required: "OTP is Required",
                      }}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100">
                    Verify
                  </button>
                </div>
              </form>
              <div className="info mt-3">
                <p className="text-muted">
                  You dont recommended to save password to browsers!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  );
};

export default Verifications;
