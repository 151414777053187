import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slice/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // List of reducers to persist
};


const reducers = combineReducers({
    user: userReducer
})
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // Add any other middleware here
});

export const persistor = persistStore(store);

export default store;
