import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import NotFound from "./Pages/Not-Found";
import Wallet from "./Pages/Wallet";
import Login from "./components/auth/login";
import OtpVerifications from "./components/auth/otpverifications";
import Reset from "./components/auth/reset";
import Signup from "./components/auth/signup";
import Verifications from "./components/auth/verifications";
import VerificationLogin from "./components/auth/verificationlogin";
import ChangePassword from "./components/auth/changepassword";
// import Profile from "./components/profile/profile";
import RequireAuth from "./config/AuthCard";
import Stripe from "./Pages/CreateStripe";
import Transection from "./Pages/Transection";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/otp-verifications" element={<OtpVerifications />} />
        <Route path="/verification" element={<Verifications />} />
        <Route path="/verificationlogin" element={<VerificationLogin />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/signup" element={<Signup />} />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route path="/wallets" element={<Wallet />} />
        {/* <Route path="/profile" element={<Profile />} /> */} 
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/transaction" element={<Transection />} />


      </Routes>
    </HashRouter>
  );
}

export default App;
