import {useState,useEffect} from "react";
import { axiosInstance } from "../../../config/AxiosInstance";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";

const Homesection2 = () => {
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const [paydata, setPaydata] = useState([])


const StripeId  = localStorage.getItem("StripeId")
const email  = localStorage.getItem("email")



  useEffect(() => {
    if(StripeId !== null){

      GetWalletBlc(StripeId)
      GetTotals(email)
    }
  
   
  }, [StripeId,email])
  
  const GetWalletBlc = (StripeId)=>{
    setLoader(true)
   
        axiosInstance
            .get(`get_wallet_balance/${StripeId}`)
            .then((response) => {
                setLoader(false)
                const data = response.data;
                console.log("getwllet",data)
                if (data) {
                  setData(data)
                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const Error = err?.response?.data?.detail
                setLoader(false)
              
                toast.error(Error);
            });

  }

  const GetTotals = (email)=>{
    setLoader(true)
        axiosInstance
            .get(`get_payment/${email}`)
            .then((response) => {
                setLoader(false)
                const data = response.data;
               
                if (data) {
                  setPaydata(data)
                } else {
                    toast.error(data.detail);
                }
            })
            .catch((err) => {
                const Error = err?.response?.data?.detail
                setLoader(false)
              
                toast.error(Error);
            });

  }


 
  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
    
    <div className="row">
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="stat-widget-1">
          <h6>Connect Wallet Balance</h6>
          <h3>$ {StripeId !== null ? data.wallet_balance: 0}</h3>
          {/* <h3>$ 500</h3> */}

         
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="stat-widget-1">
          <h6>Total Transfer Amount </h6>
          <h3>$ { paydata?.total_sent}</h3>
          {/* <h3>$ 500</h3> */}

         
        </div>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="stat-widget-1">
          <h6>Total Received Amount</h6>
          <h3>$ {  paydata?.total_received}</h3>
          {/* <h3>$ 500</h3> */}

          
        </div>
      </div>
     
    </div>
    </>
  );
};

export default Homesection2;
