import React from 'react'
import Layout from "../components/layout/layout";
import Transections from '../components/pages/transections/Transections';
export default function Transection() {
  return (
    <Layout>
        <Transections/>
    </ Layout>
  )
}
