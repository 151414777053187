import React from "react";
import Layout from "../components/layout/layout";
import Homesection from "../components/pages/home/homesection";
import Homesection2 from "../components/pages/home/homesection2";
import Homesection3 from "../components/pages/home/homesection3";

const Dashboard = () => {
  return (
    <Layout>
      <div className="container">
        <Homesection />
        <Homesection2 />
        <Homesection3 />
      </div>
    </Layout>
  );
};

export default Dashboard;
