import React from "react";
import { Controller } from "react-hook-form";

const Input = ({ name, label, control, rules, errors, type, className ,value,disabled }) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={rules}

        render={({ field }) => (
          <input
            {...field}
            type={type}
            className={`${className} form-control`}
            id={name}
        value={value}
        disabled={disabled}

          />
        )}
      />
      {errors[name] && <p className="text-danger">{errors[name].message}</p>}
    </>
  );
};

export default Input;
